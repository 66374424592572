'use client'
import { useUser } from '@/src/store/user-store'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

const ignoredRoutes = [
  '/community',
  '/contact-us',
  '/data-usage',
  '/faqs',
  '/how-earnings-work',
  '/privacypolicy',
  '/termsofservice',
]

const notAllowedRoutesLoggedOut = ['/account']

export const AuthenticationCheck = () => {
  const { user, _hasHydrated } = useUser()
  const pathname = usePathname()
  const router = useRouter()
  const searchParams = useSearchParams()

  useEffect(() => {
    const device = searchParams.get('device')
    if (user !== null && user.verifiedUser == '0' && _hasHydrated) {
      if (pathname === '/auth/tvsignup' || pathname === '/auth/tvlogin' || device === 'tv') { router.replace('/auth/verify?device=tv') } else { router.replace('/auth/verify') }
    } else if (
      user !== null &&
      user.isOnboarded == '0' &&
      _hasHydrated &&
      !ignoredRoutes.includes(pathname || '')
    ) {
      if (pathname === '/auth/tvsignup' || pathname === '/auth/tvlogin' || device === 'tv') { router.replace('/auth/verify/onboarding?device=tv') } else { router.replace('/auth/verify/onboarding') }

    } else if (
      user === null &&
      notAllowedRoutesLoggedOut.includes(pathname || '') &&
      _hasHydrated
    ) {
      if (pathname === '/auth/tvsignup' || pathname === '/auth/tvlogin' || device === 'tv') {
        const redirectUrl = localStorage.getItem('redirectUrl') || '/home?device=tv'
        router.replace(redirectUrl)
       } else {
        const redirectUrl = localStorage.getItem('redirectUrl') || '/home'
        router.replace(redirectUrl)
        }
    }
  }, [user, router, _hasHydrated, pathname])

  return <></>
}
